import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  lazy,
  Suspense,
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import { isEmpty } from 'lodash'
import Helmet from 'react-helmet'

import UserContext from '../store/app/Context'
import { store } from 'store/app/Store'
import AuthContext from '../store/auth/authContext'
import config from '../config'

import Container from '@mui/material/Container'
import Hidden from '@mui/material/Hidden'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import Skeleton from '@mui/material/Skeleton'
import Paper from '@mui/material/Paper'

import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import BedIcon from '@mui/icons-material/Bed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import Header from 'components/nav/Header'
import DetailNav from 'components/nav/secondary/DetailNav'
import Footer from 'components/nav/Footer'
import InterestDialog from 'components/common/InterestDialog'

import ListingCard from 'components/common/ListingCard'
import LoadingListingCard from 'components/results-section/LoadingListingCard'
import ListingHistory from 'components/common/ListingHistory'
import Comparables from 'components/common/Comparables'

import {
  formatCurrency,
  formatListingName,
  formatDetailPagePath,
} from 'utils/formats'
import { monthlyMortgage, calculateDownPayment } from 'utils/calcs'
import { historyBoardIds } from 'utils/constants'
// import { ArrowForwardIos } from '@mui/icons-material'
import MortgageForm from 'components/common/MortgageForm'

import lenders from 'assets/lender-lockup-v3.png'
import hwLogo from 'assets/homewise-logo.png'
import realtorca from 'assets/powered_by_realtorca.svg'
import realtor1 from 'assets/realtors/small_austin.png'
import realtor2 from 'assets/realtors/small_brett.png'
import realtor3 from 'assets/realtors/small_cyrus.png'
import realtor4 from 'assets/realtors/small_foster.png'
import realtor5 from 'assets/realtors/small_jason.png'
import realtor6 from 'assets/realtors/small_josh.png'
import realtor7 from 'assets/realtors/small_maddy.png'

const realtors = [
  realtor1,
  realtor2,
  realtor3,
  realtor4,
  realtor5,
  realtor6,
  realtor7,
]

const InterestForm = lazy(() => import('components/common/InterestForm'))
const FactsFeatures = lazy(() => import('components/common/FactsFeatures'))
const Rooms = lazy(() => import('components/common/Rooms'))
const FaqAccordion = lazy(() => import('components/common/FaqAccordion'))
const GalleryFullScreen = lazy(() => import('components/common/Gallery'))
const MonthlyPayment = lazy(() => import('components/common/MonthlyPayment'))
const ReadMoreParagraph = lazy(() =>
  import('../components/common/ReadMoreParagraph')
)
const SearchSuggestions = lazy(() =>
  import('components/common/SearchSuggestions')
)

export default function DetailView(props) {
  const isDev = process.env.REACT_APP_STAGE === 'dev'
  // const location = useLocation()
  const navigate = useNavigate()

  const { user } = useContext(AuthContext)
  const token = user?.jwtToken // user for cognito auth token in headers

  const [popular, setPopular] = useState([])
  const [nearby, setNearby] = useState([])
  const [recent, setRecent] = useState([])
  const [topSimilar, setTopSimilar] = useState([])

  let { mlsId, boardId, state, city } = useParams()

  const [listing, setListing] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [mortgage, setMortgage] = useState(0)
  const [listingHistory, setListingHistory] = useState(null)
  const [listingHistoryError, setListingHistoryError] = useState(false)
  const [factsFeatures, setFactsFeatures] = useState([])
  const [randomFacts, setRandomFacts] = useState([])
  const [fullScreen, setFullScreen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const [genericCopy, setGenericCopy] = useState('')

  const [showRooms, setShowRooms] = useState(false)

  const { fetchListingViaProxy, fetchSuggestions, fetchListingHistory } =
    useContext(UserContext)

  const { state: appState } = useContext(store)

  const [scrollPosition, setScrollPosition] = useState(0)
  const sidebarRef = useRef(null)

  const imageFormatOptions = new URLSearchParams({
    w: 800, // adjust as needed
    class: 'medium',
    f: 'webp', // fastest format for web
  })
  const imageCdn = config.repliers.CDN

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const setFullScreenImageView = (index) => {
    if (isDesktop) setCurrentImage(index)
    setFullScreen(!fullScreen)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!listing && !Object.keys(listing).length > 0) {
      const sidebarHeight = sidebarRef.current.offsetHeight
      sidebarRef.current.style.height = `${sidebarHeight}px`
    }
  }, [scrollPosition, listing])

  const sidebarClasses = ['sidebar']

  if (scrollPosition >= 128) {
    sidebarClasses.push('sticky-sidebar')
  }

  const getRoomsData = (data) => Object.values(data.rooms || [])

  const hasNonBlankAttributes = (roomData) =>
    Object.values(roomData).some(
      (attribute) => attribute !== null && attribute.trim() !== ''
    )

  const hasValidSizeData = (roomData) => {
    const sizeAttributes = ['length', 'width']
    return sizeAttributes.every((sizeAttribute) => {
      const sizeValue = roomData[sizeAttribute]
      return (
        sizeValue !== null &&
        sizeValue.trim() !== '' &&
        /^(?:\d+'\s*\d*''|\d+(?:\.\d+)?(?:'\s*\d*'')?\s*(?:m(?:\d+(?:\.\d+)?)?|meters?)?)?$/i.test(
          sizeValue
        )
      )
    })
  }

  const hasFeaturesData = (roomData) => {
    const featuresAttributes = ['features', 'features2', 'features3']
    return featuresAttributes.some((featureAttribute) => {
      const featureValue = roomData[featureAttribute]
      return featureValue !== null && featureValue.trim() !== ''
    })
  }

  const concatenateFeatures = (roomData) => {
    const featuresAttributes = ['features', 'features2', 'features3']
    const nonEmptyFeatures = featuresAttributes
      .map((featureAttribute) => roomData[featureAttribute])
      .filter(
        (featureValue) => featureValue !== null && featureValue.trim() !== ''
      )
    return nonEmptyFeatures.join(', ')
  }

  const extractStreetInfo = (streetName) => {
    const parts = streetName.trim().split(/\s+/)
    const streetNumber = /^\d+$/.test(parts[0]) ? parts.shift() : null
    let streetSuffix = null

    // List of common street suffixes
    const commonSuffixes = ['Avenue', 'Street', 'Road', 'Lane', 'Drive']

    // Iterate through the parts to find the suffix and clean the name
    for (let i = parts.length - 1; i >= 0; i--) {
      const currentWord = parts[i]

      if (commonSuffixes.includes(currentWord)) {
        streetSuffix = currentWord
        parts.splice(i)
        break
      }
    }

    return { streetNumber, streetName: parts.join(' '), streetSuffix }
  }

  useEffect(() => {
    // console.log('Use effect called', mlsId, boardId)
    window.scrollTo(0, 0)
    ;(async () => {
      try {
        setLoading(true)
        let data = {}
        console.log(
          'Preload condition check',
          appState.preloadedListing.data &&
            String(appState.preloadedListing.mlsId) === String(mlsId) &&
            Number(appState.preloadedListing.boardId) === Number(boardId)
        )
        if (
          appState.preloadedListing.data &&
          String(appState.preloadedListing.mlsId) === String(mlsId) &&
          Number(appState.preloadedListing.boardId) === Number(boardId)
        ) {
          data = appState.preloadedListing.data
          setListing(appState.preloadedListing.data)
          setLoading(false)
          console.log('Preloaded listing used', appState.preloadedListing)
        } else {
          const res = await fetchListingViaProxy(mlsId, boardId)
          data = res.data
          if (res.status >= 400) throw new Error('Error fetching listing.')

          setListing(data)
          setLoading(false)
          console.log('Preloaded listing not used', appState.preloadedListing)
        }
        setMortgage(
          monthlyMortgage(
            parseFloat(
              data.listPrice - calculateDownPayment(data.listPrice, 20)
            ),
            5,
            25
          )
        )

        if (token && historyBoardIds.includes(data.boardId.toString())) {
          const cleanData = { ...data.address }
          const streetInfo = extractStreetInfo(data.address.streetName)

          if (streetInfo.streetNumber) {
            cleanData.streetNumber = streetInfo.streetNumber
            cleanData.streetName = streetInfo.streetName
            cleanData.streetSuffix = streetInfo.streetSuffix
          }

          // If there's a valid unitNumber, add it to the address
          if (data?.address?.unitNumber) {
            cleanData.unitNumber = data?.address?.unitNumber
          }

          try {
            let params = {
              city: cleanData.city,
              state: cleanData.state,
              streetName: cleanData.streetName,
              streetNumber: cleanData.streetNumber,
              streetSuffix: cleanData.streetSuffix,
              unitNumber: cleanData.unitNumber || '',
              zip: cleanData.zip,
              boardId: data.boardId,
            }

            // if any fields are empty, null, '', undefined, remove them from the params
            Object.keys(params).forEach(
              (key) =>
                (params[key] === null ||
                  params[key] === '' ||
                  params[key] === undefined) &&
                delete params[key]
            )

            const response = await fetchListingHistory(token, params)

            if (!response) {
              console.error('No response received')
              setListingHistoryError(true)
            } else if (response.status === 404) {
              console.error('Resource not found')
              setListingHistoryError(true)
            } else if (response.status === 200) {
              let historyData = response.data.history
              setListingHistory(historyData)
            } else {
              console.error('Error:', response.status)
              setListingHistoryError(true)
            }
          } catch (error) {
            console.error('An error occurred:', error)
            setListingHistoryError(true)
          }
        }

        const details = data.details
        const labelArray = ['Property Type', 'Style', 'Sq. Ft', 'Age', 'Garage']
        const conditionList = [
          'propertyType',
          'style',
          'sqft',
          'yearBuilt',
          'garage',
        ]

        const filteredKeys = Object.keys(details).filter((key) =>
          conditionList.includes(key)
        )
        const filteredValues = filteredKeys.map((key) => details[key])

        const filteredArray = filteredValues
          .map((value, index) => {
            return {
              label: labelArray[filteredKeys.indexOf(conditionList[index])],
              value: value,
            }
          })
          .sort(
            (a, b) => labelArray.indexOf(a.label) - labelArray.indexOf(b.label)
          )

        setFactsFeatures(filteredArray)
        let lotSize =
          data.lot.depth && data.lot.width && data.lot.measurement
            ? `${data.lot.width} x ${data.lot.depth} ${data.lot.measurement}`
            : 0

        const originalDate = new Date(data.listDate)
        const formattedDate = originalDate.toISOString().split('T')[0]
        setRandomFacts([
          {
            label: `Taxes`,
            value: data.taxes.annualAmount
              ? `${formatCurrency(data.taxes.annualAmount)}`
              : `--`,
          },
          {
            label: `Lot Size`,
            value: lotSize ? lotSize : `--`,
          },
          {
            label: `Listing Date`,
            value: data.listDate ? formattedDate : `--`,
          },
          {
            label: `Updated`,
            value: data.timestamps.listingUpdated
              ? React.createElement(TimeAgo, {
                  date: `${data.timestamps.listingUpdated}`,
                })
              : `--`,
          },
          {
            label: `MLS®#`,
            value: data.mlsNumber ? `${data.mlsNumber}` : `--`,
          },
        ])

        setGenericCopy(
          `This fantastic ${
            data.details.propertyType
          } is located at ${formatListingName(data.address)} in ${
            data.address.city
          }, ${data.address.area}. With an asking price of ${formatCurrency(
            parseFloat(data.listPrice)
          )} it is sitting on a ${
            data.details.sqft !== null && data.details.sqft !== ''
              ? data.details.sqft + ' sqft lot.'
              : data.lot.depth
              ? data.lot.depth +
                ' x ' +
                data.lot.width +
                ' ' +
                data.lot.measurement +
                ' lot.'
              : null
          } ${formatListingName(data.address)} has ${
            data.details.numBedrooms
          } bedrooms and ${data.details.numBathrooms} bathrooms. This ${
            data.details.propertyType
          } is currently listed for sale with MLS®#${mlsId} located in the ${
            data.address.area
          } area.`
        )

        const roomsData = getRoomsData(data)
        let isAnyRoomWithData = false

        roomsData.forEach((roomData, roomIndex) => {
          if (hasNonBlankAttributes(roomData)) {
            isAnyRoomWithData = true
            setShowRooms(true)
          }
        })

        if (!isAnyRoomWithData) {
          setShowRooms(false)
        }

        if (!isEmpty(mlsId)) {
          const suggestionsData = await fetchSuggestions(mlsId, boardId)

          if (suggestionsData?.status >= 400)
            throw new Error(suggestionsData?.data?.message)
          else {
            setPopular(suggestionsData?.data?.suggestions?.popular)
            setNearby(suggestionsData?.data?.suggestions?.nearby)
            setRecent(suggestionsData?.data?.suggestions?.recent)
            setTopSimilar(suggestionsData?.data?.similarListings.slice(0, 4))
          }
        }
      } catch (e) {
        console.log(e)
        setLoading(false)
        setError(true)
        setTimeout(() => {
          navigate(`/list/${state}/${city}`)
        }, 3000)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListingViaProxy, mlsId, user, boardId, token])

  useEffect(() => {
    if (isEmpty(listing)) return

    const isPublic =
      listing?.permissions?.displayAddressOnInternet === 'Y' &&
      listing?.permissions?.displayPublic === 'Y'

    if (isPublic) return

    if (!user?.activeSession)
      navigate(
        `/auth/login?redirect=${formatDetailPagePath(
          listing?.address,
          listing?.mlsNumber,
          listing?.boardId
        )}`
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.activeSession, listing])

  const generateRandomHeadshot = () => {
    const randomIndex = Math.floor(Math.random() * realtors.length)
    setCurrentHeadshot(realtors[randomIndex])
  }
  const [currentHeadshot, setCurrentHeadshot] = useState(null)

  useEffect(() => {
    if (!currentHeadshot) generateRandomHeadshot()
  }, [currentHeadshot])

  return (
    <>
      <Header />
      <DetailNav
        map={listing?.map}
        mlsId={listing?.mlsNumber}
        boardId={boardId}
      />
      {loading ? (
        <>
          <div className="img-grid">
            <Box
              className="div1"
              sx={{
                height: {
                  xs: `245px`,
                  sm: `245px`,
                  md: `480px`,
                },
              }}
            ></Box>
            <Hidden mdDown>
              <div className="div2"></div>
              <div className="div3"></div>
              <div className="div4"></div>
              <div className="div5"></div>
            </Hidden>
          </div>
          <Container
            component="main"
            maxWidth="lg"
            style={{ paddingBottom: `5rem` }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Box className="main-details" sx={{ margin: `2rem 0 0` }}>
                  <Stack
                    spacing={1}
                    sx={{
                      width: {
                        xs: `100%`,
                        sm: `50%`,
                      },
                      alignItems: {
                        xs: `center`,
                        sm: `flex-start`,
                      },
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '2rem', width: `50%` }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem', width: `15rem` }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem', width: `50%` }}
                    />
                  </Stack>
                  <Stack
                    spacing={1}
                    sx={{
                      width: {
                        xs: `100%`,
                        sm: `50%`,
                      },
                      alignItems: {
                        xs: `center`,
                        sm: `flex-end`,
                      },
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '2rem', width: `5rem` }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.6rem', width: `15rem` }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '.8rem', width: `50%` }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '.8rem', width: `75%` }}
                    />
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={sidebarClasses.join(' ')} ref={sidebarRef}>
                  <Skeleton
                    variant="rounded"
                    height={300}
                    width="95%"
                    sx={{ marginTop: `30px` }}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : error ? (
        <Container component="main" maxWidth="lg" style={{ padding: `5rem 0` }}>
          <Grid container sx={{ justifyContent: `center` }}>
            <Grid item md={4}>
              <Alert severity="error">There is an error — check it out!</Alert>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          {listing && Object.keys(listing).length > 0 && (
            <>
              <Helmet>
                <title>
                  {formatListingName(listing?.address)} - Homewise Real Estate
                </title>
                <meta name="description" content={genericCopy} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta
                  itemprop="name"
                  content={`${formatListingName(
                    listing?.address
                  )} - Homewise Real Estate`}
                />
                <meta itemprop="description" content={genericCopy} />
                <meta
                  itemprop="image"
                  content={`${imageCdn}/${listing.images[0]}?${imageFormatOptions}`}
                />

                {/* <!-- Facebook Meta Tags --> */}
                <meta
                  property="og:url"
                  content="https://homewiserealestate.ca"
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:title"
                  content={`${formatListingName(
                    listing?.address
                  )} - Homewise Real Estate`}
                />
                <meta property="og:description" content={genericCopy} />
                <meta
                  property="og:image"
                  content={`${imageCdn}/${listing.images[0]}?${imageFormatOptions}`}
                />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  name="twitter:title"
                  content={`${formatListingName(
                    listing?.address
                  )} - Homewise Real Estate`}
                />
                <meta name="twitter:description" content={genericCopy} />
                <meta
                  name="twitter:image"
                  content={`${imageCdn}/${listing.images[0]}?${imageFormatOptions}`}
                />
              </Helmet>
              <GalleryFullScreen
                images={listing.images}
                handleClose={() => setFullScreen(!fullScreen)}
                isShow={fullScreen}
                currentImage={currentImage}
                address={listing.address}
                details={listing.details}
                mlsId={listing?.mlsNumber}
                boardId={listing?.boardId}
                headshot={currentHeadshot}
              />
              <div className="img-grid">
                <Box
                  className="div1"
                  sx={{
                    height: {
                      xs: `245px`,
                      sm: `245px`,
                      md: `450px`,
                    },
                    cursor: `pointer`,
                    backgroundImage: `url(${imageCdn}/${listing.images[0]}?${imageFormatOptions})`,
                    backgroundSize: `auto cover`,
                  }}
                  onClick={() => setFullScreenImageView(0)}
                >
                  <Hidden mdDown>
                    <Stack
                      direction="row"
                      sx={{
                        position: `absolute`,
                        bottom: `20px`,
                        left: `20px`,
                      }}
                    >
                      {listing.type === 'Sale' &&
                        listing.lastStatus !== 'Sld' && (
                          <Chip
                            label="For Sale"
                            color="forSale"
                            size="small"
                            sx={{ border: `1px solid #445639` }}
                          />
                        )}
                      {listing.lastStatus === 'Sld' && (
                        <Chip
                          label="Sold"
                          color="sold"
                          size="small"
                          sx={{ border: `1px solid #445639` }}
                        />
                      )}
                      {listing.type === 'Lease' && (
                        <Chip
                          label="For Lease"
                          color="forLease"
                          size="small"
                          sx={{ border: `1px solid #445639` }}
                        />
                      )}
                      <Chip
                        label={<TimeAgo date={listing.listDate} />}
                        color="neutral"
                        size="small"
                        sx={{
                          border: `1px solid #445639`,
                          marginLeft: `.5rem`,
                        }}
                      />
                    </Stack>
                  </Hidden>
                </Box>
                <Hidden mdDown>
                  {/* loop through 1 to 4 image and render */}
                  {listing.images.slice(1, 5).map((image, index) => (
                    <div
                      className={`div${index + 2}`}
                      style={{
                        backgroundImage: `url(${imageCdn}/${image}?${imageFormatOptions})`,
                        cursor: `pointer`,
                      }}
                      onClick={() => setFullScreenImageView(index + 1)}
                      key={`image-${index}`}
                    >
                      {/* {index + 2} */}
                    </div>
                  ))}
                </Hidden>

                <Hidden mdUp>
                  <Box sx={{ position: `absolute`, top: `1rem`, left: `1rem` }}>
                    {listing.type === 'Sale' &&
                      listing.lastStatus !== 'Sld' && (
                        <Chip
                          label="For Sale"
                          color="forSale"
                          size="small"
                          sx={{ border: `1px solid #445639` }}
                        />
                      )}
                    {listing.lastStatus === 'Sld' && (
                      <Chip
                        label="Sold"
                        color="sold"
                        size="small"
                        sx={{ border: `1px solid #445639` }}
                      />
                    )}
                    {listing.type === 'Lease' && (
                      <Chip
                        label="For Lease"
                        color="forLease"
                        size="small"
                        sx={{ border: `1px solid #445639` }}
                      />
                    )}
                  </Box>
                </Hidden>

                <Box
                  className="buttonHolder"
                  sx={{
                    position: `absolute`,
                    bottom: {
                      xs: `auto`,
                      sm: `auto`,
                      md: `1rem`,
                    },
                    top: {
                      xs: `1rem`,
                      sm: `1rem`,
                      md: `auto`,
                    },
                    right: `1rem`,
                  }}
                >
                  {listing.details.virtualTourUrl && (
                    <Hidden mdDown>
                      <Button
                        component="button"
                        color="primary"
                        variant="contained"
                        endIcon={<VideoCameraBackIcon />}
                        className="primaryBtn"
                        href={listing.details.virtualTourUrl}
                        target={`_blank`}
                      >
                        Virtual Tour
                      </Button>
                    </Hidden>
                  )}

                  <Button
                    component="button"
                    color="primary"
                    variant="contained"
                    endIcon={<CameraAltIcon />}
                    className="primaryBtn"
                    sx={{ marginLeft: `20px` }}
                    onClick={() => setFullScreenImageView(0)}
                  >
                    <Hidden mdDown>See All Photos</Hidden>
                    <Hidden mdUp>{listing.images?.length}</Hidden>
                  </Button>
                </Box>
                <Hidden mdUp>
                  <Box
                    sx={{
                      position: `absolute`,
                      bottom: `0px`,
                      left: `0px`,
                      width: `100%`,
                      padding: `0 0 16px 16px`,
                      boxSizing: `border-box`,
                      background: `linear-gradient(180deg,transparent,rgba(0,0,0,.7),rgba(0,0,0,.8))`,
                    }}
                  >
                    <h2 style={{ color: `#fff`, marginBottom: `0px` }}>
                      {formatListingName(listing?.address)}
                    </h2>
                    <h3 style={{ color: `#fff`, marginTop: `0px` }}>
                      {listing.address.city} -{' '}
                      <span className="secondary">{listing.address.area}</span>
                    </h3>
                  </Box>
                </Hidden>
              </div>
              <Container
                component="main"
                maxWidth="lg"
                style={{ paddingBottom: `5rem` }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Box
                      className="main-details"
                      sx={{
                        margin: `1rem 0 0`,
                      }}
                    >
                      <div>
                        {listing.lastStatus === 'Sld' ? (
                          <>
                            <h1 style={{ fontFamily: `MerriWeather` }}>
                              <span
                                style={{
                                  color: `#333`,
                                  fontFamily: `Inter`,
                                  fontSize: `1.25rem`,
                                }}
                              >
                                Sold:
                              </span>{' '}
                              <span style={{ color: `#AA482F` }}>
                                {formatCurrency(parseFloat(listing.soldPrice))}
                              </span>
                            </h1>
                            <h2
                              style={{
                                color: `#666`,
                                fontFamily: `Inter`,
                                fontSize: `1rem`,
                              }}
                            >
                              Listed:{' '}
                              <span style={{ textDecoration: `line-through` }}>
                                {formatCurrency(parseFloat(listing.listPrice))}
                              </span>
                            </h2>
                          </>
                        ) : (
                          <h1 style={{ fontFamily: `MerriWeather` }}>
                            {formatCurrency(parseFloat(listing.listPrice))}
                          </h1>
                        )}
                        <Hidden mdDown>
                          <h2>
                            <span className="highlight">
                              {formatListingName(listing?.address)}
                            </span>
                          </h2>
                          <h3>
                            {listing.address.city} -{' '}
                            <span className="secondary">
                              {listing?.address?.neighborhood
                                ? listing?.address?.neighborhood
                                : listing?.address?.area}
                            </span>
                          </h3>
                        </Hidden>
                      </div>
                      <div className="stacks">
                        <Hidden mdUp>
                          <Stack
                            direction="row"
                            sx={{
                              padding: `0 5px .5rem`,
                              justifyContent: `flex-end`,
                              marginTop: {
                                xs: `-2.5rem`,
                                sm: `-2.5rem`,
                                md: `0`,
                              },
                            }}
                          >
                            <Chip
                              label={<TimeAgo date={listing.listDate} />}
                              color="neutral"
                              size="small"
                              sx={{
                                border: `1px solid #445639`,
                                marginLeft: `.5rem`,
                              }}
                            />
                          </Stack>
                        </Hidden>
                        <p
                          className="sub-text"
                          style={{
                            marginTop: `0`,
                            marginBottom: `5px`,
                            paddingRight: `10px`,
                          }}
                        >
                          estimated mortgage payment:{' '}
                          <a
                            href={`https://my.thinkhomewise.com/m/real_estate/${mlsId}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: '#147cb9' }}
                            id="mortgageLink_text_estimated"
                          >
                            {formatCurrency(parseFloat(mortgage))} / mo
                          </a>
                        </p>
                        <Box
                          sx={{
                            display: `flex`,
                            justifyContent: `space-between`,
                          }}
                        >
                          <InterestDialog
                            cta={'Get More Details'}
                            color={'primary'}
                            variant={'contained'}
                            // className={'primaryBtnActive'}
                            sx={{
                              padding: `.25rem 1rem`,
                              fontSize: `.65rem`,
                            }}
                            size={'small'}
                            listing={listing}
                            mlsId={mlsId}
                            boardId={boardId}
                            headshot={currentHeadshot}
                            position={'detailPage_top'}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            // className="secondaryBtn"
                            target="_blank"
                            href={`https://my.thinkhomewise.com/m/real_estate/${mlsId}`}
                            sx={{
                              marginTop: `0`,
                              marginLeft: `1rem`,
                              padding: `.25rem 1rem`,
                              fontSize: `.65rem`,
                              // backgroundColor: `#eaf5e4 !important`,
                            }}
                            size="small"
                            id="mortgageLink_smBtn_preapproved"
                          >
                            Get Pre-approved
                          </Button>
                        </Box>
                      </div>
                    </Box>

                    <div className="listing-details">
                      <Stack direction="row" className="btnStack">
                        <Button
                          variant="text"
                          disabled
                          startIcon={<BedIcon className="icon" />}
                          className="notButton"
                        >
                          <span style={{ color: `#4c5257` }}>
                            {listing.details.numBedrooms + ' Beds'}
                          </span>
                        </Button>
                        <Button
                          variant="text"
                          disabled
                          startIcon={<BathtubIcon className="icon" />}
                          className="notButton"
                        >
                          <span style={{ color: `#4c5257` }}>
                            {listing.details.numBathrooms + ' Baths'}
                          </span>
                        </Button>
                        {listing.details.numGarageSpaces && (
                          <Button
                            variant="text"
                            disabled
                            startIcon={<DirectionsCarIcon className="icon" />}
                            className="notButton"
                          >
                            <span style={{ color: `#4c5257` }}>
                              {parseFloat(listing.details.numGarageSpaces) +
                                ' Garage'}
                            </span>
                          </Button>
                        )}
                      </Stack>

                      {historyBoardIds.includes(boardId) && (
                        <section className="listing-history">
                          <h2>Listing History</h2>
                          <p>
                            Buy/Sell history for{' '}
                            {formatListingName(listing?.address)}
                          </p>
                          <ListingHistory
                            data={listingHistory}
                            listing={listing}
                            error={listingHistoryError}
                          />
                        </section>
                      )}

                      <section className="facts-features">
                        <h2>Facts &amp; Features</h2>
                        <FactsFeatures
                          facts={factsFeatures}
                          random={randomFacts}
                          listedBy={listing.office?.brokerageName}
                        />
                      </section>

                      <section className="overview">
                        <h2>Overview</h2>
                        <ReadMoreParagraph
                          text={
                            listing.details.description
                              ? listing.details.description
                              : 'Blank'
                          }
                          maxLength={250}
                        />
                      </section>

                      {listing.boardId === 16 && (
                        <section
                          style={{
                            display: `flex`,
                            marginTop: `1rem`,
                            padding: `.7rem 0 1rem 0`,
                            borderTop: `1px solid #e0e0e0`,
                            borderBottom: `1px solid #e0e0e0`,
                          }}
                        >
                          <a
                            href={listing.details.moreInformationLink}
                            alt="Powered by: REALTOR.ca"
                            target="_blank"
                            rel="noreferrer nofollow"
                            style={{
                              textDecoration: `none`,
                              cursor: `default`,
                            }}
                          >
                            <img
                              src={realtorca}
                              width="90"
                              height=""
                              alt="Realtor"
                              style={{ alignSelf: `center` }}
                            />
                          </a>
                          <p
                            style={{
                              fontSize: `.7rem`,
                              lineHeight: `.8rem`,
                              padding: `0 1rem`,
                              alignSelf: `center`,
                              color: `#8A8B8C`,
                            }}
                          >
                            We do our best to provide accurate listing data,
                            which comes from the Canadian Real Estate
                            Association, but we can't guarantee it's always 100%
                            precise. The affordability tools and other info on
                            this page are for general guidance only, they're not
                            financial or investment advice. Homewise and its
                            partners don't make any promises about the accuracy
                            or completeness of this information.
                          </p>
                        </section>
                      )}

                      <Hidden mdUp>
                        <section
                          className="cta-1"
                          style={{ marginTop: `2rem` }}
                        >
                          <Suspense
                            fallback={
                              <div
                                className={sidebarClasses.join(' ')}
                                ref={sidebarRef}
                              >
                                <Skeleton
                                  variant="rounded"
                                  height={300}
                                  width="95%"
                                  sx={{ marginTop: `30px` }}
                                />
                              </div>
                            }
                          >
                            <InterestForm
                              listing={listing}
                              mlsId={mlsId}
                              boardId={boardId}
                              headshot={currentHeadshot}
                            />
                          </Suspense>
                        </section>
                      </Hidden>

                      {showRooms && (
                        <section className="rooms">
                          <Rooms
                            name={
                              formatListingName(listing?.address) +
                              ' ' +
                              listing.address.city
                            }
                            area={listing.address.area}
                            rooms={listing.rooms}
                            hasValidSizeData={hasValidSizeData}
                            hasFeaturesData={hasFeaturesData}
                            concatenateFeatures={concatenateFeatures}
                          />
                        </section>
                      )}

                      <Hidden mdUp>
                        <MortgageForm mlsNumber={mlsId} />
                      </Hidden>

                      {listing.type === 'Sale' && (
                        <section className="forSaleCalc">
                          <h2>Mortgage Payment Calculator</h2>
                          <MonthlyPayment
                            principal={parseFloat(listing.listPrice)}
                            years={25}
                            rate={5}
                            mlsId={mlsId}
                          />
                        </section>
                      )}

                      {
                        /* Dev Mode only Full Print */
                        isDev && <FaqAccordion data={listing} />
                      }

                      {listing?.comparables &&
                        listing.comparables.length > 0 && (
                          <section className="comparables">
                            <h2>Sold Comparable Listings</h2>
                            <p style={{ marginBottom: `1rem` }}>
                              Comparable listings for{' '}
                              {formatListingName(listing?.address)}
                            </p>
                            <Comparables
                              data={listing.comparables}
                              boardId={boardId}
                            />
                          </section>
                        )}

                      <section className="generated">
                        <h2>
                          About {formatListingName(listing?.address)},{' '}
                          {listing.address.city} - {listing.address.area}
                        </h2>
                        <p>{genericCopy}</p>
                        <p>
                          {listing.details.extras && listing.details.extras}
                        </p>
                        <InterestDialog
                          cta={
                            'Contact us today to schedule a showing or request more information.'
                          }
                          color={'primary'}
                          variant={'text'}
                          className={'textBtn'}
                          sx={{
                            padding: `8px 16px 6px`,
                            fontWeight: `400`,
                            letterSpacing: `0`,
                            marginTop: `.5rem`,
                            textDecoration: `underline`,
                          }}
                          listing={listing}
                          mlsId={mlsId}
                          boardId={boardId}
                          position={'detailPage_bottom'}
                        />
                      </section>
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item xs={12} md={4}>
                      <Box
                        className={sidebarClasses.join(' ')}
                        ref={sidebarRef}
                        sx={{
                          margin: {
                            xs: `0 0`,
                            sm: `0 0`,
                            md: `1rem 0 0`,
                          },
                        }}
                      >
                        <Paper
                          style={{
                            padding: `1rem 1.5rem 2rem`,
                            textAlign: `center`,
                            border: `1px solid rgba(20, 123, 201, 0.5)`,
                            boxShadow: `0px 1px 0px #EBEBEB, -12px 24px 21px rgba(0, 0, 0, 0.03), 12px 24px 21px rgba(0, 0, 0, 0.03)`,
                            borderRadius: `6px`,
                          }}
                        >
                          <Box sx={{ display: `flex`, paddingBottom: `1rem` }}>
                            {currentHeadshot && (
                              <img
                                src={currentHeadshot}
                                width="65"
                                height="65"
                                alt="Homewise Realtor"
                              />
                            )}
                            <span style={{ width: `100%` }}>
                              <h3
                                style={{
                                  marginTop: `.5rem`,
                                  marginBottom: `0`,
                                }}
                              >
                                Contact Homewise Realtor
                              </h3>
                              <p
                                style={{ marginTop: `0`, marginBottom: `1rem` }}
                              >
                                <small>Tour this home and others</small>
                              </p>
                            </span>
                          </Box>
                          <InterestDialog
                            cta={'Contact Agent'}
                            color={'primary'}
                            variant={'contained'}
                            className={'primary'}
                            sx={{ width: `100%` }}
                            size={'small'}
                            listing={listing}
                            mlsId={mlsId}
                            boardId={boardId}
                            headshot={currentHeadshot}
                            position={'detailPage_sideBar'}
                          />
                        </Paper>
                        {/* <Suspense
                          fallback={
                            <div
                              className={sidebarClasses.join(' ')}
                              ref={sidebarRef}
                            >
                              <Skeleton
                                variant="rounded"
                                height={300}
                                width="95%"
                                sx={{ marginTop: `30px` }}
                              />
                            </div>
                          }
                        >
                          <InterestForm
                            listing={listing}
                            mlsId={mlsId}
                            boardId={boardId}
                            headshot={currentHeadshot}
                          />
                        </Suspense> */}

                        <MortgageForm mlsNumber={mlsId} />
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Container>
            </>
          )}

          <Container
            component="main"
            maxWidth="lg"
            className="similar-listings"
          >
            <Grid container spacing={2}>
              {(topSimilar || loading) && (
                <Grid item xs={12}>
                  <h3>Similar Listings</h3>
                </Grid>
              )}
              {loading
                ? Array.from({ length: 4 }).map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                      <LoadingListingCard key={index} smallMode={true} />
                    </Grid>
                  ))
                : topSimilar &&
                  topSimilar?.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                      <ListingCard data={item} key={index} />
                    </Grid>
                  ))}
            </Grid>
          </Container>

          <Hidden mdUp>
            <Container
              component="div"
              maxWidth="lg"
              style={{ paddingBottom: `2rem` }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Paper
                    style={{
                      padding: `1rem 1rem 0 1rem`,
                      width: `calc(100% - 2rem)`,
                      margin: `16px 0 32px 0`,
                      textAlign: `left`,
                      border: `1px solid rgba(20, 123, 201, 0.5)`,
                      boxShadow: `0px 1px 0px #EBEBEB, -12px 24px 21px rgba(0, 0, 0, 0.03), 12px 24px 21px rgba(0, 0, 0, 0.03)`,
                      borderRadius: `6px`,
                      display: `flex`,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Hidden smDown>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{
                            display: `flex`,
                            padding: `1rem`,
                          }}
                        >
                          <div
                            style={{
                              borderRight: `1px solid rgba(20, 123, 201, 0.5)`,
                              display: `flex`,
                              justifyContent: `center`,
                              padding: `1rem 2rem 1rem 1rem`,
                            }}
                          >
                            <img
                              src={hwLogo}
                              alt="Homewise"
                              width="150"
                              style={{ display: `flex`, alignSelf: `center` }}
                            />
                          </div>
                        </Grid>
                      </Hidden>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          textAlign: 'center',
                          paddingBottom: `1rem`,
                        }}
                      >
                        <h2
                          style={{
                            marginTop: `1rem`,
                            marginBottom: `0`,
                            fontSize: `1.1rem`,
                          }}
                        >
                          Looking to get the{' '}
                          <span className="highlight">best mortgage</span>?
                        </h2>
                        <p style={{ fontSize: `.9rem`, margin: `1rem auto` }}>
                          Apply in 5 minutes to get the best rate and features
                          from <strong>30+ lenders</strong> and get a personal
                          advisor to guide you at each&nbsp;step.
                        </p>
                        <Button
                          variant="contained"
                          sx={{
                            display: `block`,
                            margin: `0 0 1rem`,
                            textTransform: `uppercase`,
                          }}
                          href={`https://my.thinkhomewise.com/m/real_estate/${props?.mlsId}`}
                          target="_blank"
                          id="mortgageLink_mobile_finalBtn"
                        >
                          {'Get Started Now'}
                        </Button>
                        <img
                          src={lenders}
                          width="300"
                          alt="Homewise Lenders"
                          style={{ width: `100%` }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Hidden>

          <SearchSuggestions
            popular={popular}
            recent={recent}
            nearby={nearby}
            loading={loading}
          />
        </>
      )}
      <Footer />
    </>
  )
}
