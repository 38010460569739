import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'components/common/CustomLink'
import { store } from 'store/app/Store'
import UserContext from 'store/app/Context'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { CardActionArea } from '@mui/material'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import BedIcon from '@mui/icons-material/Bed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import ThumbnailGallery from './ThumbnailGallery'

import line from '../../assets/separator.png'
import placeholder from '../../assets/placeholder.png'
import AuthContext from 'store/auth/authContext'

import {
  formatCurrency,
  formatDetailPagePath,
  formatListingName,
} from '../../utils/formats'
import { soldStatuses, leasedStatuses } from '../../utils/constants'
import { isSessionActive } from 'utils/session'
import { preloadListingImages } from '../../utils/imagePreloader'
import Config from '../../config'

const imageFormatOptions = new URLSearchParams({
  w: 800, // adjust as needed
  class: 'medium',
  f: 'webp', // fastest format for web
})

const DataCard = (props) => {
  const { data, boardId } = props
  const navigate = useNavigate()
  const { dispatch } = useContext(store)
  const { fetchListingViaProxy } = useContext(UserContext)
  const { user } = useContext(AuthContext)
  const [activeSession, setActiveSession] = useState(
    isSessionActive() || user?.activeSession
  )
  const isPublic =
    data?.permissions?.displayAddressOnInternet === 'Y' &&
    data?.permissions?.displayPublic === 'Y'

  // Add ref for the card element
  const cardRef = useRef(null)

  useEffect(() => {
    if (user?.activeSession !== undefined) setActiveSession(user?.activeSession)
  }, [user?.activeSession])

  const preloadListing = useCallback(async () => {
    try {
      if (!data?.mlsNumber || !data?.boardId) return

      const listing = await fetchListingViaProxy(data.mlsNumber, data.boardId)
      console.log('Preloaded listing:', listing)

      dispatch({
        type: 'SET_PRELOADED_LISTING',
        payload: {
          data: listing.data,
          mlsId: data.mlsNumber,
          boardId: data.boardId,
        },
      })

      // Preload first 5 images
      await preloadListingImages(listing.data, Config.repliers.CDN, {
        class: 'medium',
        f: 'webp',
      })
    } catch (error) {
      console.error('Error preloading listing:', error)
    }
  }, [data?.mlsNumber, data?.boardId, dispatch, fetchListingViaProxy])

  // Setup intersection observer
  useEffect(() => {
    if (!cardRef.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            preloadListing()
            // Unobserve after first intersection to prevent multiple preloads
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: null, // viewport
        rootMargin: '50px', // Start loading slightly before card becomes visible
        threshold: 0.1, // Trigger when even 10% of the card is visible
      }
    )

    observer.observe(cardRef.current)

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current)
      }
    }
  }, [preloadListing])

  return !isPublic && !activeSession ? (
    <Card
      ref={cardRef}
      className="hwCard"
      sx={{
        display: !isPublic && !activeSession ? 'grid' : 'block',
      }}
    >
      {!isPublic && !activeSession && (
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: '10px',
            position: 'absolute',
            zIndex: '20',
            width: 'auto',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <Link
            to={`/auth/login?redirect=${formatDetailPagePath(
              data?.address,
              data?.mlsNumber,
              data?.boardId || boardId
            )}`}
            style={{ color: `white` }}
          >
            LOGIN TO VIEW
          </Link>
        </Button>
      )}
      <div
        style={{
          filter: !isPublic && !activeSession ? 'blur(5px)' : 'none',
        }}
      >
        <span style={{ position: `relative` }}>
          <CardActionArea
            onClick={() => {
              navigate(
                `/auth/login?redirect=${formatDetailPagePath(
                  data?.address,
                  data?.mlsNumber,
                  data?.boardId || boardId
                )}`
              )
            }}
            sx={{ height: 235 }}
          >
            <CardMedia
              component="img"
              className="media"
              image={
                data?.images?.length >= 1
                  ? `https://cdn.repliers.io/${data.images[1]}?${imageFormatOptions}`
                  : placeholder
              }
              alt={data?.address?.streetName?.substring(0, 25) || ''}
            />
          </CardActionArea>
          <Stack
            direction="row"
            sx={{
              marginTop: `-30px`,
              padding: `0 5px`,
              zIndex: `10`,
              position: 'relative',
            }}
            justifyContent="space-between"
          >
            {data.type === 'Sale' &&
              (soldStatuses.includes(data.lastStatus) ? (
                <Chip
                  label={
                    data?.lastStatus === 'Sc' ? 'Sold Conditional' : 'Sold'
                  }
                  color="sold"
                  size="small"
                />
              ) : (
                <Chip label="For Sale" color="forSale" size="small" />
              ))}
            {data.type === 'Lease' &&
              (leasedStatuses.includes(data.lastStatus) ? (
                <Chip label="Leased" color="sold" size="small" />
              ) : (
                <Chip label="For Lease" color="forLease" size="small" />
              ))}
            {data.details.virtualTourUrl && (
              <Chip
                label="Virtual Tour"
                color="primary"
                size="small"
                component="a"
                href={`/real-estate/auth/login?redirect=${formatDetailPagePath(
                  data?.address,
                  data?.mlsNumber,
                  data?.boardId || boardId
                )}`}
                clickable
              />
            )}
          </Stack>
        </span>
        <CardActionArea>
          <Link
            to={`/auth/login?redirect=${formatDetailPagePath(
              data?.address,
              data?.mlsNumber,
              data?.boardId || boardId
            )}`}
          >
            <CardContent className="content">
              <div style={{ position: `relative` }}>
                <h2>
                  <Link
                    to={`/auth/new-account?redirect=${formatDetailPagePath(
                      data?.address,
                      data?.mlsNumber,
                      data?.boardId || boardId
                    )}`}
                    className="link"
                  >
                    Join
                  </Link>
                  &nbsp;or&nbsp;
                  <Link
                    to={`/auth/login?redirect=${formatDetailPagePath(
                      data?.address,
                      data?.mlsNumber,
                      data?.boardId || boardId
                    )}`}
                    className="link"
                  >
                    Login
                  </Link>
                  &nbsp;to view.
                </h2>
                <h3 className="truncate">
                  <span className="highlight">
                    {formatListingName(data.address)}
                  </span>
                </h3>
                <h4 className="truncate">
                  {data.address.city.substring(0, 100)},{' '}
                  {data.address.area.substring(0, 100)}{' '}
                  {data.address.neighborhood &&
                    '-' + data.address.neighborhood.substring(0, 100)}
                </h4>
                <img
                  src={line}
                  width="24"
                  height="2"
                  alt=""
                  style={{ margin: `10px 0px` }}
                />
                <Stack direction="row" sx={{ margin: `5px 0px 10px` }}>
                  {data.details.numBedrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Beds'}
                      icon={<BedIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numBathrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Baths'}
                      icon={<BathtubIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numGarageSpaces && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Garage'}
                      icon={<DirectionsCarIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                </Stack>
                <h5 className="truncate">
                  {data?.mlsNumber && `MLS® ${data?.mlsNumber}`}
                  {data?.office?.brokerageName &&
                    ` - ${data?.office?.brokerageName}`}
                </h5>
              </div>
            </CardContent>
          </Link>
        </CardActionArea>
      </div>
    </Card>
  ) : (
    <Card ref={cardRef} className="hwCard">
      <div
        onMouseEnter={window.innerWidth > 768 ? preloadListing : undefined}
        onTouchStart={window.innerWidth > 768 ? preloadListing : undefined}
      >
        <span style={{ position: `relative` }}>
          <ThumbnailGallery data={data} boardId={boardId} />
          <Stack
            direction="row"
            sx={{
              marginTop: `-30px`,
              padding: `0 5px`,
              zIndex: `10`,
              position: 'relative',
            }}
            justifyContent="space-between"
          >
            {data.type === 'Sale' &&
              (soldStatuses.includes(data.lastStatus) ? (
                <Chip label="Sold" color="sold" size="small" />
              ) : (
                <Chip label="For Sale" color="forSale" size="small" />
              ))}
            {data.type === 'Lease' &&
              (leasedStatuses.includes(data.lastStatus) ? (
                <Chip label="Leased" color="sold" size="small" />
              ) : (
                <Chip label="For Lease" color="forLease" size="small" />
              ))}
            {data.details.virtualTourUrl && (
              <Chip
                label="Virtual Tour"
                color="primary"
                size="small"
                component="a"
                href={data.details.virtualTourUrl}
                clickable
              />
            )}
          </Stack>
        </span>
        <CardActionArea>
          <Link
            to={formatDetailPagePath(
              data?.address,
              data?.mlsNumber,
              data?.boardId || boardId
            )}
          >
            <CardContent className="content">
              <div style={{ position: `relative` }}>
                <h2>{formatCurrency(parseFloat(data.listPrice))}</h2>
                <h3 className="truncate">
                  <span className="highlight">
                    {formatListingName(data.address)}
                  </span>
                </h3>
                <h4 className="truncate">
                  {data.address.city.substring(0, 100)},{' '}
                  {data.address.area.substring(0, 100)}{' '}
                  {data.address.neighborhood &&
                    '-' + data.address.neighborhood.substring(0, 100)}
                </h4>
                <img
                  src={line}
                  width="24"
                  height="2"
                  alt=""
                  style={{ margin: `10px 0px` }}
                />
                <Stack direction="row" sx={{ margin: `5px 0px 10px` }}>
                  {data.details.numBedrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={data.details.numBedrooms + ' Beds'}
                      icon={<BedIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numBathrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={data.details.numBathrooms + ' Baths'}
                      icon={<BathtubIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numGarageSpaces && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={
                        parseFloat(data.details.numGarageSpaces) + ' Garage'
                      }
                      icon={<DirectionsCarIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                </Stack>
                <h5 className="truncate">
                  {data?.mlsNumber && `MLS® ${data?.mlsNumber}`}
                  {data?.office?.brokerageName &&
                    ` - ${data?.office?.brokerageName}`}
                </h5>
              </div>
            </CardContent>
          </Link>
        </CardActionArea>
      </div>
    </Card>
  )
}

export default DataCard
