import React, { useEffect, useContext, useMemo, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import L from 'leaflet'
import { Loader } from '@googlemaps/js-api-loader'
import millify from 'millify'
import { debounce } from '@mui/material/utils'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'

import UserContext from '../../store/app/Context'
import { store } from '../../store/app/Store'
import MapOverlay from 'components/common/MapOverlay'
import * as mapConsts from './const'
import getUserCoordinates from 'utils/getUserCoordinates'

import 'leaflet.gridlayer.googlemutant'
import 'leaflet/dist/leaflet.css'
import '../../styles/leafletMap.scss'

import storageList from 'utils/storageList'
import marker_single from '../../assets/map_markers_new/marker_single.svg'
import marker_single_visited from '../../assets/map_markers_new/marker_single_visited.svg'
import marker_cluster from '../../assets/map_markers_new/marker_cluster.svg'
import marker_lease from '../../assets/map_markers_new/lease_single.svg'
import marker_lease_visited from '../../assets/map_markers_new/lease_single_visited.svg'
import lease_cluster from '../../assets/map_markers_new/lease_cluster.svg'
import sold_single from '../../assets/map_markers_new/sold_single.svg'
import sold_single_visited from '../../assets/map_markers_new/sold_single_visited.svg'
import marker_home from '../../assets/map_markers_new/marker_home.svg'

import { soldStatuses, leasedStatuses, activeStatuses } from 'utils/constants'
const expiredListings = [...soldStatuses, ...leasedStatuses]

let map = null,
  streetAddressPin = null

const LeafletMap = () => {
  const theme = useTheme()
  const provider = useContext(UserContext)
  const globalState = useContext(store)
  const { state, dispatch } = globalState

  const { coordinates, mlsId, boardId } = useParams()

  // The z param is used to set the zoom level of the map, if available
  const [params] = useSearchParams(),
    z = params?.get('z')
  const navigate = useNavigate()

  const [listing, setListing] = useState({})
  const [showOverlay, setShowOverlay] = useState(false)

  const [localSettings, setLocalSettings] = useState(state.settings)
  const [localSearchParams, setLocalSearchParams] = useState(state.searchParams)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        await request()
      }, 150),
    []
  )

  // Debounced & memoized function to handle map.setView action
  const setMapView = useMemo(
    () =>
      debounce((location, zoom = map.getZoom()) => {
        map.setView(location, zoom)
      }, 0),
    []
  )

  // Debounced & memoized function to handle map.setZoom action
  const setMapZoom = useMemo(
    () =>
      debounce((zoom) => {
        map.setZoom(zoom)
      }, 0),
    []
  )

  /**
   * A simple function to hide the map popup overlay
   * we need this as a standalone function so that we
   * can reference it in the map.on('move) event &
   * also to cleanup the event listener using the same
   * reference.
   *
   * NOTE: The memory reference is handled by the
   * leaflet library itself, so we don't need to
   * worry about it.
   * @returns void
   */
  const hideOverlay = () => {
    try {
      setShowOverlay(false)

      // if the path has coordinates, then we need to reset the map view
      // if (coordinates && mlsId && boardId) navigate('/map', { replace: true })
    } catch (err) {
      console.error('error hiding the overlay: ', err)
    }
  }

  /**
   * Function to fetch cluster data based on map bounds & render the pins on the map.
   * @returns void
   */
  const fetchClusterData = async () => {
    dispatch({ type: 'pinsStatus', newVal: 'loading' })

    let reqData = mapConsts.getMapPinsRequestData(map, state.searchParams)

    if (!reqData) return

    // this ensures the pins are within the boundary & no confusion with the explicit area/city params
    delete reqData.area
    delete reqData.city

    // if there is a state.polygon.path available, then add the city/neighborhood to reqData
    if (state.polygon.path) {
      if (state.polygon.type === 'city') reqData.city = [state.polygon.name]
      if (state.polygon.type === 'neighborhood')
        reqData.neighborhood = [state.polygon.name]
    }

    // if the map has coordinates then set the reqData.results per page to 100
    // if (coordinates && map.getZoom() >= 16) reqData.resultsPerPage = 200

    if (map.getZoom() >= 16) reqData.listings = true
    else reqData.listings = false

    try {
      const response = await provider.fetchMapPinsListings(reqData)

      // Clear existing markers
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer)
        }
      })

      // decide the zoom threshold for showing clusters
      const zoomThreshold = response?.count > 30 ? 17 : 15,
        showClusters = map.getZoom() <= zoomThreshold

      if (!showClusters) {
        const listings = response.data

        // Draw markers for each individual listing on the map
        listings.forEach((listing) => {
          const location = {
            lat: listing.map.latitude,
            lng: listing.map.longitude,
          }

          // Create a custom icon for the markers
          const customIcon = L.divIcon({
            className: 'custom-marker-icon',
            html: `<img src='${
              globalState.state.searchParams.type.includes('lease')
                ? storageList.includes(listing.mlsNumber)
                  ? marker_lease_visited
                  : expiredListings.includes(listing.lastStatus) &&
                    !activeStatuses.includes(listing.lastStatus)
                  ? sold_single
                  : marker_lease
                : storageList.includes(listing.mlsNumber)
                ? expiredListings.includes(listing.lastStatus) &&
                  !activeStatuses.includes(listing.lastStatus)
                  ? sold_single_visited
                  : marker_single_visited
                : expiredListings.includes(listing.lastStatus) &&
                  !activeStatuses.includes(listing.lastStatus)
                ? sold_single
                : marker_single
            }' style="width: 59px; margin: -23px 0 0 -23px" />
            <div class="custom-marker-icon__detailed-label">$${
              [...soldStatuses, ...leasedStatuses].includes(listing.lastStatus)
                ? millify(listing?.soldPrice || listing?.listPrice, {
                    precision: 2,
                    decimalSeparator: '.',
                    units: ['', 'K', 'M', 'B', 'T'],
                    space: false,
                  })
                : millify(listing?.listPrice, {
                    precision: 2,
                    decimalSeparator: '.',
                    units: ['', 'K', 'M', 'B', 'T'],
                    space: false,
                  })
            }</div>`,
          })

          const marker = L.marker(location, { icon: customIcon }).addTo(map)

          // Marker click event
          marker.on('click', () => {
            // remove the old map.on('move') listener
            map.off('move click')

            storageList.add(listing.mlsNumber)

            // make sure the route /map/:coordinates/:mlsNumber/:boardId is for the current listing
            if (listing?.mlsNumber && listing?.boardId)
              navigate(
                `/map/${listing?.map?.latitude};${listing?.map?.longitude}/${listing?.mlsNumber}/${listing?.boardId}`
              )

            if (mlsId === listing?.mlsNumber) setShowOverlay(true)

            setMapView(marker.getLatLng(), map.getZoom())
          })
        })
      } else {
        const clusters = response.clusters

        // Draw markers for each cluster on the map
        clusters.forEach((cluster) => {
          const location = {
            lat: cluster.location.latitude,
            lng: cluster.location.longitude,
          }

          // Create a custom icon for the markers
          const customIcon = L.divIcon({
            // iconSize: [32, 32],
            className: 'custom-marker-icon',
            html: `<img ${
              cluster?.count === 1 &&
              'style="width: 59px; margin: -23px 0 0 -23px"'
            } src='${
              globalState.state.searchParams.type.includes('lease')
                ? cluster?.count === 1
                  ? storageList.includes(cluster?.listing?.mlsNumber)
                    ? marker_lease_visited
                    : expiredListings.includes(cluster?.listing?.lastStatus)
                    ? sold_single
                    : marker_lease
                  : lease_cluster
                : cluster?.count !== 1
                ? marker_cluster
                : storageList.includes(cluster?.listing?.mlsNumber)
                ? expiredListings.includes(cluster?.listing?.lastStatus)
                  ? sold_single_visited
                  : marker_single_visited
                : expiredListings.includes(cluster?.listing?.lastStatus)
                ? sold_single
                : marker_single
            }' />
            <div class=${
              cluster?.count === 1
                ? 'custom-marker-icon__detailed-label'
                : 'custom-marker-icon__label'
            }>${
              !!cluster?.listing && cluster?.count === 1
                ? '$' +
                  ([...soldStatuses, ...leasedStatuses].includes(
                    cluster?.listing.lastStatus
                  )
                    ? millify(
                        cluster?.listing?.soldPrice ||
                          cluster?.listing?.listPrice,
                        {
                          precision: 2,
                          decimalSeparator: '.',
                          units: ['', 'K', 'M', 'B', 'T'],
                          space: false,
                        }
                      )
                    : millify(cluster?.listing?.listPrice, {
                        precision: 2,
                        decimalSeparator: '.',
                        units: ['', 'K', 'M', 'B', 'T'],
                        space: false,
                      }))
                : cluster?.count.toString()
            }</div>`,
          })

          const marker = L.marker(location, { icon: customIcon }).addTo(map)

          // Marker click event
          marker.on('click', () => {
            if (cluster?.count === 1) {
              // remove the old map.on('move') listener
              map.off('move click')

              // make sure the route /map/:coordinates/:mlsNumber/:boardId is for the current listing
              if (cluster?.listing?.mlsNumber && cluster?.listing?.boardId)
                storageList.add(cluster?.listing?.mlsNumber)
              navigate(
                `/map/${cluster?.location?.latitude};${cluster?.location?.longitude}/${cluster?.listing?.mlsNumber}/${cluster?.listing?.boardId}`
              )

              // if the route already has the current mlsNumber, then setShowOverlay(true)
              if (mlsId === cluster?.listing?.mlsNumber) setShowOverlay(true)

              setMapView(marker.getLatLng(), map.getZoom())
            } else setMapView(marker.getLatLng(), map.getZoom() + 2)
          })
        })
      }

      // if streetAddressPin is available, then add it to the map
      if (streetAddressPin) streetAddressPin.addTo(map)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }

    dispatch({ type: 'pinsStatus', newVal: 'loaded' })
  }

  /**
   * Function to handle map load and pan events
   * @returns void
   */
  const handleMapEvents = async () => {
    await fetch(() => fetchClusterData())
  }

  /** Initialize the google maps api loader;
   * this is necessary to use leaflet.gridLayer.googleMutant plugin.
   * The reason we use this plugin instead of the default leaflet tileLayer
   * is to comply with the google maps terms of service.
   * @see (10.1.a) https://developers.google.com/maps/terms-20180207#:~:text=10.1%20Administrative%20Restrictions.
   */
  const loader = new Loader(mapConsts.loaderOptions)

  /** Render the map & handle any re-renders based on store changes */
  useEffect(() => {
    try {
      if (map !== undefined && map !== null) map.remove()

      // Ensure the overlay is hidden on mount & re-renders
      setShowOverlay(false)

      let point = state.settings.center,
        zoom = state.settings.zoom

      ;(async () => {
        // Load the google maps api for the leaflet.gridLayer.googleMutant plugin
        await loader.load()
      })()

      if (coordinates) {
        point = {
          lat: Number(coordinates.split(';')[0]),
          lng: Number(coordinates.split(';')[1]),
        }

        if (z) zoom = isNaN(Number(z)) ? 17 : Number(z)
        else zoom = 17
      } else {
        ;(async () => {
          try {
            // if the path has coordinates, then exit
            if (coordinates && mlsId && boardId) return

            const userLocation = await getUserCoordinates()

            point = userLocation
            dispatch({
              type: 'settings',
              newVal: { ...state.settings, center: userLocation, zoom: 14 },
            })
            // sessionStorage.setItem('userLocation', true)
          } catch (errorTranslationLink) {
            // showToast(errorTranslationLink, 'default')
            console.error('error getting user location: ', errorTranslationLink)
          }
        })()
      }

      // Create a Leaflet map instance
      map = L.map('map1', {
        zoomControl: false, // Remove zoom control buttons from the map
        zoom,
        center: point,
        minZoom: 4,
        maxZoom: 18,
      })

      // hides the default leaflet attribution
      map.attributionControl.setPrefix('')

      // Add a tile layer to the map (using unlicensed google maps data)
      // L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      //   subdomains: ["mt0", "mt1", "mt2", "mt3"],
      //   attribution: '<a href="https://www.google.com/maps"> &copy; Google Maps</a>',
      // }).addTo(map)

      // Add a tile layer to the map (using OpenStreetMap data)
      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: 'Map data © OpenStreetMap contributors',
      // }).addTo(map)

      // Add the Google Maps layer using the GoogleMutant plugin
      L.gridLayer
        .googleMutant({
          type: state.satelliteView ? 'satellite' : 'roadmap',
          maxZoom: 18,
          styles: mapConsts.mapStylesArray,
        })
        .addTo(map)

      // Event listeners for map load and pan events
      // map.on('load', handleMapEvents)
      map.whenReady(handleMapEvents)

      map.on('moveend', () => {
        ;(async () => {
          const center = map.getCenter(),
            zoom = map.getZoom()

          setLocalSearchParams({
            clusterPrecision: zoom + 2,
            clusterLimit: zoom <= 8 ? 200 : 100,
            resultsPerPage: zoom >= 16 ? 200 : 30,
            listings: zoom >= 16 ? true : false,
          })

          setLocalSettings({
            center,
            zoom,
          })
        })()
      })
    } catch (error) {
      console.warn('Something went wrong while rendering the map:', error)
    } finally {
      // Cleanup function to remove event listeners when component unmounts
      return () => {
        if (map !== undefined && map !== null)
          map.off('load moveend move click')

        // unload the google maps api
        loader.deleteScript()
        // map.remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.resultsSection.open, state.satelliteView])

  /** Listen for changes to localSettings & append updates to the store */
  useEffect(() => {
    ;(async () => {
      dispatch({
        type: 'settings',
        newVal: {
          ...state.settings,
          ...localSettings,
        },
      })

      handleMapEvents()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSettings])

  /** Listen to state.settings.center changes and adjust the map zoom and center */
  useEffect(() => {
    if (map !== undefined && map !== null) {
      // if the center is the same, just exit
      if (map.getCenter().equals(state.settings.center)) return

      // if the center is the same as the localSettings.center, then exit
      // if (map.getCenter().equals(localSettings.center)) return

      // if coordinates are available & the map is already centered on the coordinates, then exit
      if (coordinates) {
        const coords = {
          lat: Number(coordinates.split(';')[0]),
          lng: Number(coordinates.split(';')[1]),
        }

        if (map.getCenter().equals(coords)) return
      }

      setMapView(state.settings.center)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings.center])

  /** Listen to state.settings.zoom changes and adjust the map zoom */
  useEffect(() => {
    if (map === undefined || map === null) return

    // if the zoom is the same, just exit
    if (map.getZoom() === state.settings.zoom) return

    setMapZoom(state.settings.zoom)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings.zoom])

  /** Listen for changes to localSearchParams & append updates to the store */
  useEffect(() => {
    const searchParamsWithMapBounds = mapConsts.getMapPinsRequestData(
      map,
      localSearchParams
    )
    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        ...searchParamsWithMapBounds,
      },
    })

    handleMapEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchParams])

  /** Listen to state.searchParams changes and re-render the map pins */
  useEffect(() => {
    if (map === undefined || map === null) return

    handleMapEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchParams])

  /** Listen to changes to coordinates & if not null, then set the map center to the coordinates & zoom to 18 */
  useEffect(() => {
    if (!map) return
    if (!coordinates || !boardId || !mlsId) return

    try {
      const point = {
        lat: Number(coordinates.split(';')[0]),
        lng: Number(coordinates.split(';')[1]),
      }

      setListing({ mlsNumber: mlsId, boardId: boardId })
      setShowOverlay(true)

      // remove the old map.on('move') listener so that the overlay doesn't disappear
      map.off('move click')

      // if the current center is the same as the new center, just exit
      if (map.getCenter().equals(point)) return

      dispatch({
        type: 'settings',
        newVal: { ...state.settings, center: point },
      })
    } catch (err) {
      console.log('error fetching a listing for a point', err)
      navigate('/map')
      setShowOverlay(false)
    }
    // eslint-disable-next-line
  }, [coordinates, mlsId, boardId, window?.Location?.path])

  /** Listen to state.polygon changes & draw a polygon on the map using the bounds & zoom out just a bit to show the entire polygon */
  useEffect(() => {
    if (!map) return

    // Clear all the old polygons
    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) map.removeLayer(layer)
    })

    if (!state.polygon.name || !state.polygon.type) {
      dispatch({
        type: 'polygon',
        newVal: {
          ...state.polygon,
          path: null,
        },
      })

      handleMapEvents()

      return
    }

    // Fetch the polygon data from provider.fetchPolygonData
    ;(async () => {
      const polygonData = await provider.fetchPolygonData(
        state.polygon.name,
        state.polygon.type
      )
      // const polygonData = require('./polygon.json')

      // if the polygonData is null, then center to the state.polygon.center & exit
      if (!polygonData) {
        dispatch({
          type: 'polygon',
          newVal: {
            ...state.polygon,
            path: null,
          },
        })

        // center to the state.polygon.center
        map.setView(state.polygon.center)

        handleMapEvents()

        return
      }

      const transformedData = polygonData[0].map(([lng, lat]) => [lat, lng])

      // draw the polygon with the polygonData that is [[lng, lat], [lng, lat], ...]
      const polygon = L.polygon(transformedData, {
        color: 'black',
        // transparent fill
        fillColor: '#000',
        fillOpacity: 0,
      }).addTo(map)

      dispatch({
        type: 'polygon',
        newVal: {
          ...state.polygon,
          // set the map param with the polygonData in the format `[[[lng, lat], [lng, lat], ...]]]]`
          path: `[[${polygonData[0].map(([lng, lat]) => `[${lng}, ${lat}]`)}]]`,
        },
      })

      // zoom out just a bit to show the entire polygon
      map.fitBounds(polygon.getBounds().pad(0.01))

      handleMapEvents()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.polygon.name,
    state.polygon.type,
    state.polygon.center,
    state.resultsSection.open,
  ])

  /** If the state.streetAddressMarker.visible is true, then add a marker to the map at the streetAddressMarker.location */
  useEffect(() => {
    if (!map) return

    if (state.streetAddressPin.visible) {
      // A custom pin icon using PushPin material-ui icon
      const customIcon = L.divIcon({
        className: 'custom-marker-icon',
        html: `<img src='${marker_home}'/>`,
        iconSize: [50, 50],
        // offset the pin so that the point is at the bottom middle of the pin
        iconAnchor: [12, 36],
      })

      streetAddressPin = L.marker(state.streetAddressPin.location, {
        icon: customIcon,
        draggable: false,
      }).addTo(map)
    } else {
      if (state.streetAddressPin.marker) {
        map.removeLayer(streetAddressPin)
        streetAddressPin = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.streetAddressPin])

  return (
    <>
      {state.pinsStatus === 'loading' ? (
        <div
          style={{
            maxWidth: theme.spacing(7),
            height: theme.spacing(6),
            minWidth: 0,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.white,
            },
            border: '1px solid ',
            borderColor: theme.palette.primary.main,
            borderRadius: '8px',
            boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`,
            position: 'absolute',
            bottom: theme.spacing(10),
            left: theme.spacing(2),
            padding: `1rem 1rem 0.5rem 1rem `,
            zIndex: 999,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}
      {showOverlay && (
        <MapOverlay
          listing={listing}
          setShowOverlay={setShowOverlay}
          callback={() => {
            map.on('move', hideOverlay)
            map.on('click', hideOverlay)
          }}
        />
      )}
      <div
        id="map1"
        style={
          isMobile
            ? {
                height: `calc(100dvh - 113px)`,
                margin: `0`,
              }
            : { height: `calc(100dvh - 163px)`, margin: `0` }
        }
      ></div>
    </>
  )
}

export default LeafletMap
