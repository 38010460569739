import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'

import axios from 'axios'
import Config from 'config'
import parse from 'parse-link-header'

import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'

import AuthContext from '../../store/auth/authContext'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import logo from '../../assets/HWRE-logo-colour.png'

import Wrapper from './Wrapper'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const context = useContext(AuthContext)
  const { login } = context

  const [schemaUrl, setSchemaUrl] = useState('')
  const schema = useSchema(schemaUrl)

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(context?.loading)
  const [searchParams] = useSearchParams()
  const redirect = searchParams?.get('redirect')
  const navigate = useNavigate()
  const [t] = useTranslation()

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.CUSTOMER + `/meta?group=homewise`)
      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      // console.log(urlsObject)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'authLogin') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !schemaUrl.length

    if (isSchemaEmpty) {
      getSchema()
      isSchemaEmpty = false
    }
  }, [schemaUrl])

  const is_email = (email) => {
    let minRegex = new RegExp(
      /^([^<>()[\],;:\s@"]+)@([^<>()[\],;:\s@"]+\.)+[a-zA-Z]{2,4}$/
    )
    return minRegex.test(email)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const validate = () => {
    let isError = false
    // empty errors object
    Object.keys(errors).forEach(function (k) {
      delete errors[k]
    })

    if (password.length < 8) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: t('common:errors.userDetailsPasswordErrorLength'),
      }))
    }

    if (password.length < 1) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: t('common:errors.userDetailsPasswordError'),
      }))
    }

    return isError
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors([])
    setLoading(true)

    // Temporary is_email check
    const seemsEmaily = is_email(email)

    if (seemsEmaily) {
      const data = {
        email: email,
        password: password,
      }
      // console.log(data, schema)

      const err = validateSchema(t, schema, data, 'errors')
      const passwordErr = validate()
      if (!passwordErr) {
        if (!err) {
          try {
            login(data, setErrors, setLoading, async () => {
              // console.log('success')
              if (redirect && redirect !== '/real-estate') {
                // console.log('redirect', redirect)
                navigate(redirect?.replace('/real-estate', ''), {
                  replace: true,
                })
              } else {
                // console.log('redirect', '/')
                navigate('/')
              }
            })
          } catch (error) {
            setLoading(false)
            // console.log('login catch error', error)
          }
        } else {
          setLoading(false)
          setErrors({
            ...err,
          })
          console.table(err)
        }
      } else {
        setLoading(false)
      }
    } else {
      // console.log('email is not valid')
      setLoading(false)
      setErrors({
        email: t('errors:email.format'),
      })
    }
  }

  return (
    <Wrapper>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} width="220" alt="Homewise Real Estate" />
        <h1
          style={{
            margin: `2rem auto`,
            textAlign: `center`,
            fontSize: `1.8rem`,
          }}
        >
          Welcome back to <span className="highlight">Homewise</span>
        </h1>
        <p style={{ textAlign: `center` }}>
          Check in on <strong>your hand picked homes</strong>. Login with your
          Homewise account details.
        </p>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            size="small"
            sx={{ backgroundColor: 'white' }}
            onChange={(e) => {
              setEmail(e.target.value)
              if (errors.email) {
                delete errors.email
              }
            }}
            error={errors.email ? true : false}
            helperText={errors.email ? errors.email : ''}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            size="small"
            sx={{ backgroundColor: 'white' }}
            onChange={(e) => {
              setPassword(e.target.value)
              if (errors.password) {
                delete errors.password
              }
            }}
            error={errors.password ? true : false}
            helperText={errors.password ? errors.password : ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ display: `block`, margin: `2rem auto` }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: `#ffffff`,
                }}
                size={20}
                thickness={4}
              />
            ) : (
              'Login'
            )}
          </Button>
          <Grid container>
            <Grid item xs style={{ textAlign: `center` }}>
              <Link
                to={`/auth/verify${window?.location?.search}`}
                variant="body2"
                className="link"
              >
                Forgot password?
              </Link>
              <br />
              <br />
              <Link
                to={`/auth/new-account${window?.location?.search}`}
                variant="body2"
                className="link"
              >
                Create an account
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 5 }}
      >
        <a
          href="https://thinkhomewise.com/page/terms/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Terms of Use
        </a>
        {' | '}
        <a
          href="https://thinkhomewise.com/page/privacy/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Privacy Policy
        </a>
      </Typography>
    </Wrapper>
  )
}

export default withTranslation('landing')(Login)
