import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useNavigate } from 'utils/hooks/useCustomNavigate'
import { Link } from 'components/common/CustomLink'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'

import placeholder from '../../assets/placeholder.png'
import { formatDetailPagePath } from '../../utils/formats'

const ThumbnailGallery = (props) => {
  const { data, isOverlay = false, boardId } = props
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const imageFormatOptions = new URLSearchParams({
    w: 350, // adjust as needed
    class: 'small',
    f: 'webp', // fastest format for web
  })

  // if images are more than 4, slice the first 4 images
  const images =
    data?.images?.length > 4 ? data.images.slice(0, 4) : data?.images || []
  const [activeImageIndex, setActiveImageIndex] = React.useState(0)
  const [preloadedImages, setPreloadedImages] = useState([])

  const handleNext = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const handlePrevious = () => {
    setActiveImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    )
  }

  const preloadAllImages = () => {
    if (preloadedImages.length === 0 && images?.length > 0) {
      const loadedImages = []
      images.forEach((imgUrl) => {
        const image = new Image()
        image.src = `https://cdn.repliers.io/${imgUrl}?${imageFormatOptions}`
        loadedImages.push(image)
      })
      setPreloadedImages(loadedImages)
    }
  }

  const styles = {
    pagination: {
      position: `absolute`,
      textAlign: `center`,
      transition: `opacity 300ms ease 0s`,
      transform: `translate3d(0px, 0px, 0px) translateX(-50%)`,
      zIndex: `10`,
      width: `80px`,
      left: `50%`,
      whiteSpace: `nowrap`,
      overflow: `hidden`,
      fontSize: `0`,
      bottom: `10px`,
      opacity: `1`,
    },
    paginationDot: {
      transition: `transform 200ms ease 0s, left 200ms ease 0s, -webkit-transform 200ms ease 0s`,
      left: `0px`,
      margin: `0 4px`,
      display: `inline-block`,
      width: `8px`,
      height: `8px`,
      borderRadius: `100%`,
      opacity: `0.2`,
      background: `rgb(0, 0, 0)`,
      backgroundColor: `#fff`,
    },
    paginationDotActive: {
      opacity: `1`,
      transform: `scale(1)`,
      backgroundColor: `#fff`,
    },
    paginationDotPrev: {
      scale: `0.66`,
      opacity: `0.6`,
    },
    paginationDotPrevPrev: {
      scale: `0.33`,
      opacity: `0.6`,
    },
    paginationDotNext: {
      transform: `scale(0.66)`,
      opacity: `0.6`,
    },
    paginationDotNextNext: {
      transform: `scale(0.33)`,
      opacity: `0.6`,
    },
  }

  return (
    <div
      className="thumbnail-gallery"
      onMouseEnter={preloadAllImages}
      // onTouchStart={preloadAllImages}
    >
      {images?.length > 0 ? (
        <>
          {images?.length > 1 && (
            <>
              <IconButton
                onClick={handlePrevious}
                sx={{
                  position: 'absolute',
                  left: '3%',
                  top: isOverlay ? '40%' : '50%',
                  zIndex: '20',
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                  color: 'white',
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                <ArrowBackIcon fontSize={'medium'} />
              </IconButton>
              <IconButton
                onClick={handleNext}
                sx={{
                  position: 'absolute',
                  right: isOverlay ? (isMobile ? '61%' : '56%') : '3%',
                  top: isOverlay ? '40%' : '50%',
                  zIndex: '20',
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                  color: 'white',
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                <ArrowForwardIcon fontSize={'medium'} />
              </IconButton>
              {activeImageIndex === images?.length - 1 && (
                <div
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    placeContent: `center`,
                    transition: `opacity 150ms ease 0s`,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      position: 'absolute',
                      alignSelf: 'center',
                      zIndex: '20',
                      width: isOverlay ? (isMobile ? '80px' : 'auto') : 'auto',
                      top: {
                        xs: isOverlay ? `24%` : `40%`,
                        md: isOverlay ? `32%` : `42%`,
                      },
                    }}
                  >
                    <Link
                      to={formatDetailPagePath(
                        data?.address,
                        data?.mlsNumber,
                        data?.boardId || boardId
                      )}
                      style={{ color: `white` }}
                    >
                      View More
                    </Link>
                  </Button>
                </div>
              )}
            </>
          )}
          <CardActionArea
            onClick={() => {
              navigate(
                formatDetailPagePath(
                  data?.address,
                  data?.mlsNumber,
                  data?.boardId || boardId
                )
              )
            }}
          >
            {images?.length > 1 && (
              <Container
                sx={{ height: '100%', display: { xs: 'none', md: 'block' } }}
              >
                <div style={{ ...styles.pagination }}>
                  {/* A span dot representing previous images */}
                  {images.map((image, index) => (
                    <span
                      key={index}
                      // if index is 2 or more less than activeImageIndex, then apply paginationDotPrevPrev style
                      // if index is 1 less than activeImageIndex, then apply paginationDotPrev style
                      // if index is 1 more than activeImageIndex, then apply paginationDotNext style
                      // if index is 2 or more more than activeImageIndex, then apply paginationDotNextNext style
                      // if index is equal to activeImageIndex, then apply paginationDotActive style
                      style={{
                        ...styles.paginationDot,
                        ...(index <= activeImageIndex - 2
                          ? styles.paginationDotPrevPrev
                          : {}),
                        ...(index === activeImageIndex - 1
                          ? styles.paginationDotPrev
                          : {}),
                        ...(index === activeImageIndex
                          ? styles.paginationDotActive
                          : {}),
                        ...(index === activeImageIndex + 1
                          ? styles.paginationDotNext
                          : {}),
                        ...(index >= activeImageIndex + 2
                          ? styles.paginationDotNextNext
                          : {}),
                      }}
                    ></span>
                  ))}
                </div>
              </Container>
            )}
            <CardMedia
              component="img"
              className="media"
              image={`https://cdn.repliers.io/${data.images[activeImageIndex]}?${imageFormatOptions}`}
              alt={data?.address?.streetName?.substring(0, 25) || ''}
              sx={{
                filter:
                  images?.length > 1 && activeImageIndex === images?.length - 1
                    ? 'blur(4px)'
                    : 'none',
                transition: 'filter 50ms ease-in-out',
              }}
            />
          </CardActionArea>
        </>
      ) : (
        <CardActionArea
          onClick={() => {
            navigate(
              formatDetailPagePath(
                data?.address,
                data?.mlsNumber,
                data?.boardId || boardId
              )
            )
          }}
        >
          <CardMedia
            component="img"
            className="media"
            image={placeholder}
            alt={data?.address?.streetName?.substring(0, 25) || ''}
          />
        </CardActionArea>
      )}
    </div>
  )
}

export default ThumbnailGallery
