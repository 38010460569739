import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import { useNavigate } from 'utils/hooks/useCustomNavigate'
import { Loader } from '@googlemaps/js-api-loader'
import * as c from '../../map/const'
import { NumericFormat } from 'react-number-format'

import Chip from '@mui/material/Chip'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import BedIcon from '@mui/icons-material/Bed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import placeholder from '../../../assets/placeholder.png'
import { debounce } from '@mui/material/utils'
import { styled } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import { startCase, lowerCase } from 'lodash'

import { formatDetailPagePath, formatListingName } from '../../../utils/formats'
import { soldStatuses, leasedStatuses } from '../../../utils/constants'
import UserContext from 'store/app/Context'
import { store } from '../../../store/app/Store'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 0,
  padding: '10px 8px 10px 4px',
  color: theme.palette.primary.secondary,
  fontSize: '1.2rem',
}))

const autocompleteService = { current: null }
const imageFormatOptions = new URLSearchParams({
  w: 220, // adjust as needed
  class: 'small',
  f: 'webp', // fastest format for web
})

const LocationResults = ({ results, loading, handleChange }) => {
  return (
    <Grid container spacing={1} sx={{ padding: '0 16px' }} direction={'column'}>
      <Grid
        item
        sx={{
          width: '100%',
          wordWrap: 'break-word',
          marginLeft: '-4px',
        }}
      >
        <Box component="span" sx={{ fontWeight: 700 }}>
          <Typography variant="h6" color="text.primary">
            <GroupHeader>Locations</GroupHeader>
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={0}
        sx={{ padding: '2px 0 4px 12px' }}
      >
        {loading ? (
          // 3 array items with skeleton loading cards
          [...Array(3)].map((item, index) => (
            <Grid
              item
              sx={{ width: '100%', wordWrap: 'break-word' }}
              key={index}
            >
              <Box sx={{ display: `flex`, flexGrow: 1 }}>
                <h2>
                  <Skeleton variant="text" width={300} />
                </h2>
              </Box>
            </Grid>
          ))
        ) : results?.length > 0 ? (
          results?.map((result, index) => (
            <Grid
              item
              sx={{ width: '100%', wordWrap: 'break-word', marginTop: '-12px' }}
              key={index}
            >
              <Button
                variant="text"
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  textAlign: 'left',
                  color: 'inherit',
                  margin: 0,
                  padding: 0,
                  fontFamily: 'Arial',
                }}
                onClick={() => {
                  handleChange(null, result)
                }}
              >
                <div
                  style={{
                    // align first p to the left, second p to the right
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontWeight: 'regular', fontSize: '1.1rem' }}>
                    {result.description}
                  </p>
                  <p style={{ textAlign: 'right' }}>
                    {result?.structured_formatting?.secondary_text}
                  </p>
                </div>
              </Button>
            </Grid>
          ))
        ) : (
          <Grid item>No Locations found</Grid>
        )}
      </Grid>
      <Grid item>
        <hr />
      </Grid>
    </Grid>
  )
}

/** A group of listing cards with each card having a thumbnail on the left */
const ListingResults = ({ results, loading, handleChange }) => {
  const navigate = useNavigate()
  return (
    <Grid container spacing={2} sx={{ padding: '0 16px' }} direction={'column'}>
      <Grid
        item
        sx={{
          width: '100%',
          wordWrap: 'break-word',
          marginLeft: '-4px',
        }}
      >
        <Box component="span" sx={{ fontWeight: 700 }}>
          <Typography variant="h6" color="text.primary">
            <GroupHeader>Listings</GroupHeader>
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ padding: '2px 0 0 10px' }}
      >
        {loading ? (
          // 3 array items with skeleton loading cards
          [...Array(3)].map((item, index) => (
            <Grid
              item
              sx={{ width: '100%', wordWrap: 'break-word' }}
              key={index}
            >
              <Card
                elevation={0}
                sx={{
                  height: '120px',
                }}
              >
                <CardActionArea>
                  <Box sx={{ display: `flex`, flexGrow: 1 }}>
                    <Skeleton
                      variant="rectangular"
                      height={100}
                      // width={220}
                      style={{
                        borderRadius: '6px',
                        width: '100%',
                        margin: '8px',
                      }}
                    />
                    <CardContent style={{ padding: '0 10px 0px 10px' }}>
                      <div style={{ position: `relative` }}>
                        <h2>
                          <Skeleton variant="text" width={200} />
                        </h2>
                        <h3>
                          <Skeleton variant="text" width={125} />
                        </h3>
                        <h4 style={{ marginTop: `10px` }}>
                          <Skeleton variant="text" width={200} />
                        </h4>
                      </div>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : results?.length > 0 ? (
          results?.map((result, index) => (
            <Grid
              item
              sx={{ width: '100%', wordWrap: 'break-word' }}
              key={index}
            >
              <Card
                elevation={0}
                sx={{
                  height: '120px',
                  backgroundColor: 'transparent',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    handleChange(null, result)
                    navigate(
                      `${formatDetailPagePath(
                        result?.address,
                        result?.mlsNumber,
                        result?.boardId
                      )}`
                    )
                  }}
                >
                  <Box sx={{ display: `flex` }}>
                    <CardMedia
                      component="img"
                      style={{
                        borderRadius: '6px',
                        maxWidth: '128px',
                        margin: '8px',
                      }}
                      image={
                        result?.images?.length >= 1
                          ? `https://cdn.repliers.io/${result.images[0]}?${imageFormatOptions}`
                          : placeholder
                      }
                      alt={result?.description?.substring(0, 25) || ''}
                    />
                    <CardContent sx={{ padding: '0 10px 0px 4px' }}>
                      {/* <div style={{ position: `relative` }}> */}
                      <Stack
                        direction="row"
                        sx={{ marginTop: `10px` }}
                        spacing={4}
                        justifyContent="space-evenly"
                      >
                        <h2
                          style={{
                            fontFamily: 'Merriweather',
                            fontWeight: 500,
                            color: '#054d85',
                            margin: 0,
                          }}
                        >
                          {result.listPrice ? (
                            <NumericFormat
                              value={result.listPrice}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              allowNegative={false}
                            />
                          ) : (
                            '$---'
                          )}
                        </h2>
                        <div style={{ display: 'flex' }}>
                          {result.listingType === 'Sale' &&
                            (soldStatuses.includes(result.lastStatus) ? (
                              <Chip
                                label={
                                  result?.lastStatus === 'Sc'
                                    ? 'Sold Conditional'
                                    : 'Sold'
                                }
                                color="sold"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="For Sale"
                                color="forSale"
                                size="small"
                              />
                            ))}
                          {result.listingType === 'Lease' &&
                            (leasedStatuses.includes(result.lastStatus) ? (
                              <Chip label="Leased" color="sold" size="small" />
                            ) : (
                              <Chip
                                label="For Lease"
                                color="forLease"
                                size="small"
                              />
                            ))}
                        </div>
                      </Stack>
                      <h3 className="truncate" style={{ marginBottom: '-8px' }}>
                        <span className="highlight">
                          {formatListingName(result?.address)?.substring(0, 25)}
                        </span>
                      </h3>
                      <h4 style={{ marginTop: `10px` }}>
                        {result?.structured_formatting?.secondary_text}
                      </h4>
                      <Stack direction="row" spacing={0.5}>
                        {result?.details?.numBedrooms && (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={result?.details?.numBedrooms}
                            icon={<BedIcon />}
                            sx={{ border: `0px` }}
                          />
                        )}
                        {result?.details?.numBathrooms && (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={result.details.numBathrooms}
                            icon={<BathtubIcon />}
                            sx={{ border: `0px` }}
                          />
                        )}
                        {result?.details?.numGarageSpaces && (
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={parseFloat(result.details.numGarageSpaces)}
                            icon={<DirectionsCarIcon />}
                            sx={{ border: `0px` }}
                          />
                        )}
                      </Stack>
                      {/* </div> */}
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item sx={{ marginLeft: '6px' }}>
            No Listings found
          </Grid>
        )}
      </Grid>
      <Grid item>
        <hr />
      </Grid>
    </Grid>
  )
}

// const AddressResults = ({ results, loading, handleChange }) => {
//   return (
//     <Grid container spacing={1} sx={{ padding: '0 16px' }} direction={'column'}>
//       <Grid
//         item
//         sx={{
//           width: '100%',
//           wordWrap: 'break-word',
//           marginLeft: '-4px',
//         }}
//       >
//         <Box component="span" sx={{ fontWeight: 700 }}>
//           <Typography variant="h6" color="text.primary">
//             <GroupHeader>Addresses</GroupHeader>
//           </Typography>
//         </Box>
//       </Grid>
//       <Grid
//         container
//         alignItems="center"
//         spacing={0}
//         sx={{ padding: '2px 0 4px 12px' }}
//       >
//         {loading ? (
//           // 3 array items with skeleton loading cards
//           [...Array(3)].map((item, index) => (
//             <Grid
//               item
//               sx={{ width: '100%', wordWrap: 'break-word' }}
//               key={index}
//             >
//               <Box sx={{ display: `flex`, flexGrow: 1 }}>
//                 <h2>
//                   <Skeleton variant="text" width={300} />
//                 </h2>
//               </Box>
//             </Grid>
//           ))
//         ) : results?.length > 0 ? (
//           results?.map((result, index) => (
//             <Grid
//               item
//               sx={{ width: '100%', wordWrap: 'break-word', marginTop: '-12px' }}
//               key={index}
//             >
//               <Button
//                 variant="text"
//                 sx={{
//                   width: '100%',
//                   justifyContent: 'flex-start',
//                   textTransform: 'none',
//                   textAlign: 'left',
//                   color: 'inherit',
//                   margin: 0,
//                   padding: 0,
//                 }}
//                 onClick={() => {
//                   handleChange(null, result)
//                 }}
//               >
//                 <div
//                   style={{
//                     // align first p to the left, second p to the right
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     width: '100%',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <p style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
//                     {result.description}
//                   </p>
//                   <p style={{ textAlign: 'right' }}>
//                     {result?.structured_formatting?.secondary_text}
//                   </p>
//                 </div>
//               </Button>
//             </Grid>
//           ))
//         ) : (
//           <Grid item>No Addresses found</Grid>
//         )}
//       </Grid>
//       <Grid item>
//         <hr />
//       </Grid>
//     </Grid>
//   )
// }

const SearchMobile = (props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const resultsLimit = isMobile ? 3 : 6

  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const { autocompleteSearch, fetchLocations } = useContext(UserContext)

  const mapStatus = useRef(state.mapStatus.loaded)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const [inputValue, setInputValue] = useState(state.propertyInfoAddress)
  const [value, setValue] = useState(state.propertyInfoAddress)

  const theme = useTheme()

  useEffect(() => {
    // use the google maps loader to load the google maps api
    ;(async () => {
      const loader = new Loader(c.loaderOptions)

      try {
        await loader.load()
        mapStatus.current = true
        dispatch({ type: 'mapStatus', newVal: { loaded: true } })
      } catch (err) {
        console.log('error loading map for search: ', err)
      }
    })()
    // eslint-disable-next-line
  }, [])

  const searchOptions = {
    componentRestrictions: { country: 'CA' },
    // allow geocode i.e., cities, addresses, etc.
    types: ['geocode'],
    // for location biasing, see:
    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#location
    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#locationbias
    locationbias: 'ipbias',
  }

  const fetch = useMemo(
    () =>
      debounce(async (request, callback) => {
        await autocompleteService.current.getPlacePredictions(
          { ...request, ...searchOptions },
          callback
        )
      }, 200),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    let active = true
    setLoading(true)

    try {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService()
      }

      if (!autocompleteService.current) {
        setLoading(false)
        return undefined
      }

      if (inputValue === '') {
        setOptions(value ? [value] : [])
        setValue('')
        dispatch({ type: 'propertyInfoAddress', newVal: '' })
        setLoading(false)
        return undefined
      }

      fetch({ input: inputValue }, async (results) => {
        if (active) {
          let newOptions = []

          if (value) newOptions = [value]

          if (results) {
            // loop through the results and add a type: 'place' to each result
            results = results?.slice(0, resultsLimit)?.map((result) => {
              result.type = 'addresses'
              result.description = result?.structured_formatting?.main_text

              // remove the country 'Canada' from the secondary text
              result.structured_formatting.secondary_text =
                result?.structured_formatting?.secondary_text?.replace(
                  ', Canada',
                  ''
                )

              return result
            })

            // if a result.description is the country name 'Canada', remove that result
            results = results.filter(
              (result) => result?.description !== 'Canada'
            )

            if (inputValue.length >= 2) {
              // split the input into components
              const query = inputValue?.split(',').map((item) => item.trim())

              // const listings = await autocompleteSearch(query[0]), locations = await fetchLocations(query[0]);

              // make concurent requests to the api for listings and locations
              const [listings, locations] = await Promise.all([
                autocompleteSearch(query[0]),
                fetchLocations(query[0]),
              ])

              if (locations?.search) {
                let areas = []

                if (locations?.search?.neighborhoods?.length > 0) {
                  const neighborhoods = locations?.search?.neighborhoods
                    .slice(0, resultsLimit)
                    .map((neighborhood) => {
                      // if a result[].structured_formatting.main_text is the same as startCase(lowerCase(`${neighborhood?.name}`)), remove that result
                      if (
                        results?.some(
                          (result) =>
                            result?.structured_formatting?.main_text ===
                            startCase(lowerCase(`${neighborhood?.name}`))
                        )
                      ) {
                        results = results.filter(
                          (result) =>
                            result?.structured_formatting?.main_text !==
                            startCase(lowerCase(`${neighborhood?.name}`))
                        )
                      }

                      return {
                        type: 'locations',
                        description: startCase(
                          lowerCase(`${neighborhood?.name}`)
                        ),
                        structured_formatting: {
                          main_text: startCase(
                            lowerCase(`${neighborhood?.name}`)
                          ),
                          secondary_text:
                            neighborhood?.city && neighborhood?.state
                              ? `${startCase(
                                  lowerCase(neighborhood?.city)
                                )}, ${startCase(
                                  lowerCase(neighborhood?.state)
                                )}`
                              : 'Neighborhood',
                          // generate the matched_substrings array from formatListingName(result.address)
                          main_text_matched_substrings: [],
                        },
                        place_id: neighborhood?.name,
                        state: neighborhood?.state,
                        city: neighborhood?.city,
                        reference: neighborhood?.name,
                        matched_substrings: [],
                        address: {
                          location: neighborhood?.location,
                          type: 'neighborhood',
                        },
                        images: null,
                      }
                    })

                  areas = [...areas, ...neighborhoods]
                }

                results = [...results, ...areas]
              }

              if (listings?.listings) {
                // loop through the results and add a type: 'listings' to each result & reformat the object to match the google places object
                const transformedResults = listings?.listings
                  ?.slice(0, resultsLimit)
                  ?.map((result) => {
                    return {
                      type: 'listings',
                      description: formatListingName(result.address),
                      structured_formatting: {
                        main_text: formatListingName(result.address),
                        secondary_text: `${startCase(
                          lowerCase(`${result?.address?.city}`)
                        )}, ${startCase(
                          lowerCase(`${result?.address?.state}`)
                        )}`,
                        // generate the matched_substrings array from formatListingName(result.address)
                        main_text_matched_substrings: [],
                      },
                      place_id: result?.mlsNumber,
                      reference: result?.mlsNumber,
                      matched_substrings: [],
                      address: result?.address,
                      mlsNumber: result?.mlsNumber,
                      boardId: result?.boardId,
                      images: result?.images,
                      listPrice: result?.listPrice,
                      listingType: result?.type,
                      lastStatus: result?.lastStatus,
                      listDate: result?.listDate,
                      details: result?.details,
                    }
                  })

                // combine the google places results with the listings results
                results = [...results, ...transformedResults]
              }
            }

            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
          setLoading(false)
        }
      })

      return () => {
        active = false
      }
    } catch (e) {
      console.log('error in search initialization: ', e)
      setLoading(false)
      return () => {
        active = false
      }
    }
    // eslint-disable-next-line
  }, [value, inputValue, fetch, autocompleteSearch, fetchLocations, dispatch])

  const handleChange = (event, newValue) => {
    if (!newValue) return

    let defaultZoom = 14

    if (newValue?.type === 'listings') {
      dispatch({
        type: 'propertyInfoAddress',
        newVal: newValue?.description,
      })
      navigate(
        formatDetailPagePath(
          newValue?.address,
          newValue?.mlsNumber,
          newValue?.boardId
        )
      )
    } else if (newValue?.type === 'locations') {
      setOptions(newValue ? [newValue, ...options] : options)
      setValue(newValue)

      dispatch({
        type: 'polygon',
        newVal: {
          name: newValue?.description,
          type: newValue?.address?.type,
          center: newValue?.address?.location,
        },
      })

      dispatch({
        type: 'propertyInfoAddress',
        newVal: newValue?.description,
      })

      // if the path has 'list' then persist the state and city in the url
      if (window?.location?.pathname?.includes('list'))
        navigate(`/list/${newValue?.state}/${newValue?.city}`)
      else navigate('/map')
    } else {
      setOptions(newValue ? [newValue, ...options] : options)
      setValue(newValue)

      dispatch({
        type: 'propertyInfoAddress',
        newVal: newValue?.structured_formatting?.main_text,
      })

      // geocode the place_id
      if (newValue?.place_id) {
        const geocoder = new window.google.maps.Geocoder()

        geocoder.geocode({ placeId: newValue?.place_id }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const address_components = results[0].address_components
              const addressItems = address_components.reduce(
                (seed, { short_name, types }) => {
                  types.forEach((t) => {
                    seed[t] = short_name
                  })
                  return seed
                },
                {}
              )

              const provinceLongName = address_components.find((item) =>
                item.types.includes('administrative_area_level_1')
              ).long_name

              // get coordinates
              const lat = results[0]?.geometry?.location?.lat()
              const lng = results[0]?.geometry?.location?.lng()

              dispatch({
                type: 'settings',
                newVal: { ...state.settings, center: { lat, lng }, zoom: 16 },
              })

              dispatch({
                type: 'searchParams',
                newVal: {
                  ...globalState.state.searchParams,
                  // streetName: addressOnly.trim(), // disabled for now, we don't want to search by street name
                  area: provinceLongName,
                  city: [addressItems?.locality],
                },
              })

              // if the address has street_number, route, and locality, then send the coordinates to state.streetAddressPin
              if (
                addressItems?.street_number &&
                addressItems?.route &&
                addressItems?.locality
              ) {
                dispatch({
                  type: 'streetAddressPin',
                  newVal: {
                    visible: true,
                    location: {
                      lat,
                      lng,
                    },
                  },
                })

                defaultZoom = 17
              }

              navigate(`/map/${lat};${lng}?z=${defaultZoom}`)
            }
          }
        })
      }
    }
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={0}
        sx={{
          padding: '8px 16px 8px 0',
          display: 'flex',
          backgroundColor: theme.palette.primary.main,
        }}
        // disableRestoreFocus={true}
      >
        <Button
          component="button"
          color="primary"
          variant="outlined"
          sx={{
            border: 0,
            borderRadius: 0,
            margin: '0 -4px 0 -6px',
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon
            sx={{ alignItems: 'center', color: theme.palette.background.light }}
          />
        </Button>
        <TextField
          placeholder={t('search.label')}
          fullWidth
          sx={{
            backgroundColor: `#fff`,
            borderRadius: `8px`,
          }}
          id="search"
          name="search"
          autoComplete="search"
          autoFocus
          InputProps={{
            'aria-label': t('search.label'),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CancelIcon
                  onClick={() => {
                    setInputValue('')
                    setValue('')
                    dispatch({ type: 'propertyInfoAddress', newVal: '' })
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={inputValue}
          inputProps={{
            onInput: (e) => {
              setInputValue(e.target.value)
            },
          }}
          // onInput={(e) => {
          //   setInputValue(e.target.value)
          // }}
          onChange={(event, newValue) => {
            handleChange(event, newValue)
          }}
        />
      </Stack>
      {inputValue && (
        <React.Fragment>
          <LocationResults
            results={options.filter((option) => option.type !== 'listings')}
            loading={loading}
            handleChange={handleChange}
          />
          <ListingResults
            results={options.filter((option) => option.type === 'listings')}
            loading={loading}
            handleChange={handleChange}
          />
          {/* <AddressResults
            results={options.filter((option) => option.type === 'addresses')}
            loading={loading}
            handleChange={handleChange}
          /> */}
        </React.Fragment>
      )}
    </>
  )
}

export default SearchMobile
