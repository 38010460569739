const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

export const preloadListingImages = async (listing, imageCdn, imageFormatOptions) => {
  if (!listing?.images?.length) return

  const format = new URLSearchParams(imageFormatOptions).toString()
  
  const imagesToPreload = listing.images.slice(0, 5)
  const preloadPromises = imagesToPreload.map(image => 
    preloadImage(`${imageCdn}/${image}?${format}`)
  )
  
  try {
    await Promise.all(preloadPromises)
  } catch (error) {
    console.error('Error preloading images:', error)
  }
}